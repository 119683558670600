<template>
  <div class="page" style="padding: 10px">
    <Breadcrumb :model="items"/>
    <DataTable class="p-mt-4" ref="dt" :value="plan" v-model:selection="selectedProducts" dataKey="id"
               :auto-layout="true" frozenWidth="20%">
      <template #header>
        <div style="display: flex">
          <Button  class="p-mr-2" label="发布活动" icon="pi pi-fw pi-plus" @click="goto"/>
          <InputText  type="text" class="p-mr-2" v-model="name" placeholder="活动名称"/>

          <Calendar @clear-click="getData"  onfocus=this.blur() placeholder="活动日期" v-model="time"
                    dateFormat="yy-dd-mm" :show-button-bar="true"/>
          <Button class="p-ml-2" @click="getData">查询</Button>
          <Button :class="{'p-disabled':selectedProducts.length==0}" @click="showInvitePeople"
                  class="p-ml-2" label="邀请参会人员"/>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column field="name" header="活动名称"></Column>
      <Column field="time" header="活动时间">
        <template #body="slotProps">
          {{new Date(slotProps.data.time).toLocaleString() }}
        </template>
      </Column>
      <Column field="type" header="活动所属类别"></Column>
      <Column field="requestPeopleNum" header="邀请人数"></Column>
      <Column field="submitPeopleNum" header="确认参与人数"></Column>
      <Column field="addr" header="活动地点"></Column>
      <Column field="operation" header="操作" header-style="width:20%">
        <template #body="slotProps">
          <Button class="p-button-sm p-m-2" @click="gotoView(slotProps.data)">参会人员列表</Button>
          <Button class="p-button-sm p-m-2" @click="goto(slotProps.data)">编辑</Button>
          <Button class="p-button-sm p-button-danger p-m-2" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
        </template>
      </Column>
    </DataTable>
    <Paginator @page="changePage" :rows="page.size" :totalRecords="page.total" :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
               template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"></Paginator>
    <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要删除 <b>{{ product.name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
      </template>
    </Dialog>
    <ActivityManagement @close="closeDialog" ref="dat" v-if="dat"></ActivityManagement>
    <InvitePeople v-on:close="adduser=false" v-if="adduser" ref="InvitePeople"></InvitePeople>
    <Toast/>
  </div>
</template>

<script>
import ActivityManagement from "./ActivityManagement";
import InvitePeople from "@/views/modules/public/InvitePeople";

export default {
  mounted() {
    this.getData()
  },
  name: "",
  components: {ActivityManagement, InvitePeople},
  data() {
    return {
      dat: false,
      template: null,
      fileUploadUrl: this.$global_msg.frontendUrl + "/activityRelease/upload",
      adduser: false,
      name: '',
      time: null,
      plan: [],
      items: [
        {label: '活动管理', to: '/activityList'},
      ],

      deleteProductDialog: false,
      product: {},
      selectedProducts: [],
      filters: {},
      submitted: false,
      page:{
        current:1,
        size:10,
        total:0,
      }
    }
  },

  methods: {
    showInvitePeople() {
      this.adduser = true
      this.$nextTick(() => {
        this.$refs.InvitePeople.init(1, this.selectedProducts)
      })
    },
    hide() {
      this.dat = false;
      this.getData();
    },

    goto(data) {
      this.dat = true;
      this.$nextTick(() => {
        this.$refs.dat.init(data)
      })
    },
    closeDialog() {
      this.dat = false
      this.getData()
    },
    gotoView(data) {
      this.$router.push('/participants?id=' + data.id + '&tableType=1')
    },

    getData() {
      this.$http.get('/activity/showActivityList',{
        params:{
          current:this.page.current,
          size:this.page.size,
          name:this.name,
          time:this.time
        }
      }).then((res) => {
        this.plan = res.data.records
        this.page.total=res.data.total
      })
    },
    changePage(event) {
      this.page.size = event.rows
      this.page.current = event.page + 1
      this.getData()
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {
      this.$http.get('/activity/delOneActivity', {params: {'id': id}}).then((res) => {
        this.deleteProductDialog = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.plan = res.data
      })
    },

    downloadTemplate() {
      window.open('/static/file/people_template.xlsx')
    },
  }
}
</script>

<style scoped>

</style>