<template>
  <Dialog style="width: 70%" v-model:visible="show" header="上传接收人员" @hide="closeDialog">
    <DataTable style="width: 100%" ref="dt" :value="peopleList" dataKey="id"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
      <template #header>
        <div style="display: flex">
          <Button class="p-m-2" label="下载模板" @click="downloadTemplate()"/>
          <FileUpload class="p-m-2" :auto="true" name="file" :url="fileUploadUrl"
                      @upload="userListUpload"
                      mode="basic"/>
        </div>
      </template>
      <template #empty>
        <p style="width: 100%;text-align: center">没有发现数据。</p>
      </template>
      <Column :sortable="false" field="companiesName" header="公司名称"></Column>
      <Column field="peopleName" header="人员名称" :sortable="false"></Column>
      <Column field="phoneNum" header="手机号码" :sortable="false"></Column>
      <Column :exportable="false" field="operation" header="操作" headerStyle="width:25%;">
        <template #body="a">
          <Button class="p-button-danger p-button-sm" @click="delRow(a.data)">删除</Button>
        </template>
      </Column>
      <template #footer>
        <Button @click="submitUserList" label="完成邀请"/>
      </template>
    </DataTable>

  </Dialog>
</template>

<script>
export default {
  name: "InvitePeople",
  data() {
    return {
      ids:null,
      show:false,
      fileUploadUrl: this.$global_msg.frontendUrl + "/activityRelease/upload",
      tableType:null,
      peopleList:[]
    }
  },
  methods: {
    init(tableType,ids){
      this.ids=ids
      this.tableType=tableType
      this.show=true
    },
    closeDialog(){
      this.show=false
      this.$emit('close')
    },
    downloadTemplate() {
      window.open('/static/file/people_template.xlsx')
    },
    submitUserList() {
      let ids = [];
      for (let i in this.ids) {
        ids.push(this.ids[i].id)
      }

      this.$http.post('/activityRelease/addUserList?ids=' + ids + '&tableType='+this.tableType).then(() => {
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
        this.show = false;
      })
    },
    userListUpload(event){
      console.log(event)
      this.peopleList  = JSON.parse(event.xhr.response)
    },
    delRow(data){
      this.$http.post('/activityRelease/delOneCaChe',data).then((res)=>{
        this.peopleList  = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>